.dashboard.jetadmin .page__main {
    margin: 0px 0px 0px 0px !important;
    padding-top: 0;
}

.dashboard.jetadmin .page__main .dashboard {
    padding: 15px 0px;
    min-height: auto;
}

.dashboard.jetadmin .block{
    max-width: 160px;
    min-width: 160px;
    width: 160px;
}

.bg_primary {
    background-color: #146B90 !important;
    border-radius: 8px;
}

.bg_green {
    background-color: #22A298;
    border-radius: 8px;
}

.bg_yellow {
    background-color: #F4B532;
    border-radius: 8px;
}

.bg_pink {
    background-color: #F59390;
    border-radius: 8px;
}

.bg_red {
    background-color: #ED483D;
    border-radius: 8px;
}

a.btn-link.export {
    border-radius: 4px;
    border: 1px solid var(--1-primary-colors-blue, #146B90);
    background: var(--3-text-colors-white, #FFF);
    color: #146b90;
    padding: 8px 55px;
    text-decoration: none;
}

.react-datepicker__portal {
    position: absolute !important;
    width: 150px !important;
    min-width: 150px !important;
    height: auto !important;
    background-color: transparent !important;
    left: auto !important;
    top: auto !important;
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
    font-size: 0.944rem !important;
}

.flex-container {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  align-items: center; /* Align items vertically in the center */
}

.btn-clear {
    height: 40px;
    line-height: 1;
    font-size: 12px;
    margin-left: 1rem;
}

.react-datepicker__input-container input {
    outline: none;
    border: 1px solid #ddd;
    display: block;
    width: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzIxMjUyOSc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+) !important;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    position: relative;
    cursor: pointer;
}

/*
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: #146B90;
    color: #fff;
}

.react-datepicker__day:hover{
    border-radius: 50%;
}*/

.tooltip {
    position: relative;
    background: #fff;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 390px;
    bottom: 100%;
    left: 50%;
    margin-left: -195px;
    background-color: #494242;
    color: #fff;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 999;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0.5px;
    font-family: sans-serif;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }