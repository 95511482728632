@import url('https://fonts.googleapis.com/css?family=Inter');

:root {
  --bs-font-inter: 'Inter';
  --bs-body-main: #F4F4F4 !important;
  --bs-link-color: #146B90 !important;
  --bs-link-color-rgb: 20, 107, 144 !important;
  --bs-primary: #146B90 !important;
  --bs-primary-colors-cyan: #22A298;
  --bs-mid-gray: #CCCCCC;
  --bs-dark-gray: #949494;
}

body {
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-family: var(--bs-font-inter);
  margin: 0;
  cursor: default;
}

code {
  font-family: 'Inter';
}

.location .form-control::-ms-input-placeholder { /* Edge 12-18 */
  text-transform: initial !important;
}

.location .form-control::placeholder {
  text-transform: initial !important;
}

a,
a:hover {
  color: #146B90;
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-weight-600 {
  font-weight: 600;
}

.mt-28 {
  margin-top: 28px;
}

.mt-24 {
  margin-top: 24px;
}

.PL-80 {
  padding-left: 80px;
}

.custom-bg {
  background-color: #146B90 !important;
}

.text-primary {
  color: #146B90 !important;
}

.text-mid-gray {
  color: var(--bs-mid-gray);
}

.text-dark-gray {
  color: var(--bs-dark-gray);
}

.text-green{
  color: var(--bs-primary-colors-cyan);
}

.btn-primary {
  border-radius: 4px !important;
  background: #146B90 !important;
  border-color: #146B90 !important;
  padding: 12px 12px !important;
  font-weight: 500;
}

.btn-secondary {
  border-radius: 4px;
  border: 1px solid var(--1-primary-colors-blue, #146B90) !important;
  background: var(--3-text-colors-white, #FFF) !important;
  color: #146B90 !important;
  border-radius: 4px !important;
}

.btn-activate {
  background-color: var(--bs-primary-colors-cyan) !important;
  color: #fff !important;
}

.btn-md {
  border-radius: 4px !important;
  padding: 10px 50px !important;
  font-weight: 500;
}

.form-control {
  border-color: #49454F !important;
  border-radius: 4px !important;
  padding: 0.75rem 0.75rem !important;
}

.form-control:focus {
  border-color: inherit;
  outline: 0;
  box-shadow: none !important;
}

.error {
  color: #ff0000;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  z-index: 99;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
  height: 10px;
}

.breadcrumb-item+.breadcrumb-item:before {
  content: ">" !important;
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.table>:not(caption)>*>*{
  vertical-align: middle;
  padding: 0.5rem 1rem !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: #40474b0a !important;
}

/* #admin-dash-header {
  background-color: #fff;
  padding: 17px 40px;
  height: 90px;
}

.navbar_logo {
  width: 137px;
  height: 55px; */

code {
  font-family: 'Inter';
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  float: right;
}

.font-20 {
  font-size: 20px;
}

.font-26 {
  font-size: 26px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-60 {
  margin-top: 64px !important;
}

.mt-80 {
  margin-top: 84px !important;
}

.padding-40 {
  padding: 40px;
}

.PL-80 {
  padding-left: 80px;
}

.w-65 {
  width: 50%;
}

.radius-0 {
  border-radius: 0 !important;
}

.btn-primary-color {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #146B90 !important;
  --bs-btn-border-color: #146B90;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #146B90;
  --bs-btn-hover-border-color: #146B90;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146B90;
  --bs-btn-active-border-color: #146B90;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #146B90;
  --bs-btn-disabled-border-color: #146B90;
}

.btn-default {
  --bs-btn-color: #146B90 !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #146B90 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #146B90;
  --bs-btn-hover-border-color: #146B90 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146B90;
  --bs-btn-active-border-color: #146B90;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #146B90;
  --bs-btn-disabled-border-color: #146B90;
}

.primary_color {
  color: #146B90;
}

.white_color {
  color: #fff;
}

.btn-secondary-color {
  --bs-btn-color: #000 !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.hori_line {
  margin: 49px 0px;
  border-top: 1px solid #ccc;
}

.header {
  width: 100%;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 40px 18px 40px;
  background-color: #fff;
  border-radius: 0px 0px 26.67px 26.67px;
  z-index: 9999;
  transition: 0.5s;
}

.header.scrollable {
  position: fixed;
}

.navbar_logo {
  width: 137px;
  height: 55px;
}

.Toastify__toast-container--top-right {
  top: 2em !important;
  right: 1em;
  z-index: 999999999999 !important;
}

select#language-picker {
  background-color: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  font-weight: 600;
}

select#language-picker option {
  color: #191919;
}

.header ul {
  border: none;
  -webkit-transition: top 2s ease 0s;
  transition: top 2s ease 0s;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  transform: translate(25px, 86px) !important;
}

.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background: #F4B532;
}

.username {
  position: relative;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;
}

.username::before {
  content: " ";
  border-left: 1px solid #ddd;
  margin-right: 0px;
  padding-right: 15px;
  position: absolute;
  height: 25px;
  top: auto;
  left: -14px;
}

ul.dropdown-menu {
  border-radius: 0px 0px 8px 8px;
  background: #FFF;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
  width: 180px;
  text-align: left;
}

ul.dropdown-menu li:last-child {
  border-bottom: none;
}

ul.dropdown-menu li {
  border-bottom: 1px solid #ccc;
  margin: 0px 7px;
  padding: 5px 0;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bs-dropdown-link-color) !important;
  background-color: transparent !important;
}

.header .dropdown-toggle::after {
  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 332px;
  background-color: #FFFFFF;
  /* padding: 0.5rem 1rem 0 0;
  transition: 1.5s; */
  /* z-index: var(--z-fixed); */
  /* -webkit-box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.1); */
  padding: 117px 15.35px 39px 40px;
}

.sidebar-menu {
  height: calc(100vh - 200px);
  transition: 0.4s;
}

.sidebar-menu a.active {
  color: var(--1-primary-colors-blue, #146B90);
  font-weight: 600;
}

.sidebar-menu.min-height {
  height: calc(100vh - 440px);
}

.sidebar-menu a.active img {
  filter: invert(67%) sepia(41%) saturate(457%) hue-rotate(154deg) brightness(93%) contrast(89%);
}

.sidebar-profile .initials {
  position: relative;
  margin: 5px auto;
  font-size: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-profile .initials::after {
  background-color: #F4B532;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: " ";
  z-index: -9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky-btn {
  position: sticky;
  bottom: 30px;
  left: 40px;
  right: auto;
  top: auto;
  width: 225px;
}

.admin-dash-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.page__main {
  padding-top: 2.5rem;
  background-color: #F4F4F4;
  margin: 50px 0px 0px 332px;
  /* height: 100vh; */
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 999999;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #146b90 !important;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

.location .form-control{
  text-transform: capitalize;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.footer {
  border-top: 1.333px solid var(--3-text-colors-light-grey, #E1E1E1);
  padding: 49px 39px;
  height: auto;
  z-index: 9999;
  position: relative;
  background: #fff;

}

.footer-copyright {
  color: #5A5A5A;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 34.02px;
  letter-spacing: 1.333px;
}

.social-link {
  margin-right: 22px;
}

.footer-email {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.footer-logo {
  width: 100%;
  padding: 25px 0px;
}

.dashboard {
  padding: 42px 40px;
  min-height: calc(100vh - 270px);
}

.dashboard.jetadmin {
  min-height: auto;
}

.dashboard .card {
  --bs-card-border-color: transparent;
  --bs-card-border-radius: 20px;
}

.dashboard .card-body {
  padding: 20px 28px;
}

.upload-image {
  border-radius: 5.333px;
  border: 2.333px dashed #CCC;
  padding: 20px;
  margin-top: 32px;
}

.course {
  margin-top: 26px;
}

.button-default {
  font-size: 21px;
  color: #146B90;
}

.edit_btn {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.45) !important;
  backdrop-filter: blur(2px) !important;
  z-index: 99999999;
  pointer-events: none;
}

.ReactModal__Body--open {
  height: 100vh;
  overflow-y: hidden;
  pointer-events: none;
}

.ReactModal__Content {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  border-radius: 20px !important;
  padding: 30px 40px 30px !important;
  transform: translate(-50%, -50%) !important;
  min-width: 600px !important;
  width: auto;
  pointer-events: auto;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 29.363px;
  background: #146b90;
  outline: none;
  border: none;
  font-size: 1rem;
  color: #fff;
  padding: 1px 1px 1px;
  line-height: 1;
}

.ReactModal__Content img {
  width: 150px;
  height: 150px;
}

.videoModal {
  position: fixed;
  min-width: auto !important;

}

.statistics {
  border-radius: 20px;
  background: #F4F4F4;
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
}

.tooltip-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.tooltip-label {
  font-weight: bold;
}

input.numeric {
  border: 0;
  outline: none;
  background-color: transparent;
  width: 150px;
}

/* Add this CSS to hide the left sidebar */
.rdrMonthAndYearWrapper,
.rdrMonths {
  display: none;
}

/* Adjust the width of the calendar */
.rdrDateRangeWrapper {
  max-width: 300px;
  /* Adjust the width as needed */
}

.org-admin td span {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}

.org-admin td span:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 992px) {
  .language-picker {
    display: none;
  }

  .username {
    display: none;
  }
}

.add_btn {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.table {
  border-radius: 5px !important;
}

.thead-dark {
  --bs-table-bg: #CCC;
}

.table th {
  padding: 15px !important;
  vertical-align: middle;
}

.has-search .form-control {
  padding-left: 3rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding: 8px;
}

.has-search .form-control {
  padding: 0.75rem 0.75rem;
  border-radius: 50px !important;
  margin-bottom: 29px;
  border: var(--bs-border-width) solid #146B90;
}

.licence_box {
  padding: 22.667px 25px;
  border-radius: 13.333px;
  border: 1.333px solid var(--3-text-colors-white, #FFF);
  background: var(--1-primary-colors-blue, #146B90);
  align-items: center;
}

.licence_box .licence-used {
  position: relative;
}

.licence_box .licence-used::after {
  content: " ";
  border-left: 1px solid #ccc;
  position: absolute;
  height: 60px;
  top: 15px;
  left: auto;
  right: 125px;
}

.licences_btn {
  float: right;
  width: 250px;
}

.licences_btn span {
  font-size: 1rem;
}

.learning_lable {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 23px;
  color: #000;
}

hr.learning_hr {
  border-radius: 0px 0px 4px 4px;
  padding: 0px 18px;
}

.side_verticalline {
  border-right: 1.5px solid #146B90;
  height: auto;
}

.progressbar_learner {
  width: 45px;
  height: 45px;
  margin: 0px auto;
  margin-bottom: 20px;
}

.progressbar_learner .CircularProgressbar {
  height: 45px;
  width: 45px;
}

svg.CircularProgressbar {
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.badge {
  padding: 6.667px 15px !important;
  font-size: 12px !important;
  width: 110px;
}

.inprogress-badge {
  background: rgba(20, 107, 144, 0.18);
  color: var(--1-primary-colors-blue, #146B90) !important;
}

.completed-badge {
  color: var(--1-primary-colors-cyan, #22A298) !important;
  background: rgba(34, 162, 152, 0.18);
}

.notstarted-badge {
  color: var(--1-primary-colors-yellow, #F4B532) !important;
  background: rgba(244, 181, 50, 0.18);
}

.notaccepted-badge {
  color: var(--1-primary-colors-red, #ED483D) !important;
  background: rgba(237, 72, 61, 0.18);
}

.revoked-badge {
  color: var(--3-text-colors-black, #000) !important;
  background: var(--3-text-colors-mid-grey, #CCC);
}

.entities button.btn-close {
  filter: invert(1) brightness(290%);
  opacity: 1;
  font-size: 12px;
}

.progressbar {
  width: 160px;
}

.nosidebar-page__main {
  padding-top: 2.5rem;
  background-color: #F4F4F4;
  margin: 50px 0px 0px 0px;
}

.profile-form-wrapper {
  width: 75%;
}

.select {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.form-select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 12px 10px !important;
  border-color: #49454F !important;
  border-radius: 4px !important;
  height: 50px;
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 50px 0 0;
  padding: 0 0;
  list-style: none;
  border-radius: 0px 0px 4px 4px;
  background: #FFF;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);

}

.options.active {
  display: block;
}

.options li {
  margin: 0 0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.img_upload {
  text-decoration: underline;
  color: #146B90;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  top: auto;
  width: 100%;
  text-align: center;
}

.primary-contact {
  border-radius: 8px;
  background: #F3F3F3;
}

.stats .statistics canvas{
  max-height: 402px;
}

/* .statistics.sold-used canvas {
  max-height: 800px;
  display: inline-block !important;
} */

.card-footer {
  border-radius: 0 0px 20px 20px !important;
}

.custom-tooltip,
.custom-tooltip2 {
  border-radius: 5.322px;
  background: #FFF;
  box-shadow: 0px 4px 13.31px 0px rgba(0, 0, 0, 0.15);
  padding: 0px;
  position: absolute;
  opacity: 0;
  transform: translate(-155px, 15px);
  transition: opacity 0.1s ease;
  width: 180px;
}

.tooltip-content {
  color: black;
}

.custom-tooltip a,
.custom-tooltip2 a {
  display: block;
  border-radius: 0px 0px 5.32px 5.32px;
  background: var(--2-secondary-s-blue, #D9E8EE);
  padding: 10px;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}

.tooltip-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.custom-tooltip::after,
.custom-tooltip2::after {
  content: "";
  position: absolute;
  top: 42%;
  left: -8%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  transform: rotate(90deg);
}

.dropdown-toggle::after {
  display: none !important;
}

.back-link {
  border-radius: 10px;
  border: 1px solid var(--3-text-colors-mid-grey, #CCC);
  padding: 4px 0px;
}

.sidebar-menu .back-link a {
  font-size: 1rem !important;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  border-radius: 4px;
  border: 0.5px solid var(--3-text-colors-light-grey, #E1E1E1);
  background: var(--3-text-colors-white, #FFF);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.13);
  width: 230px;
  text-align: left;
  padding: 10px 20px;
  cursor: default;
  pointer-events: none;
}

.filter-dropdown button {
  cursor: pointer;
  pointer-events: auto;
}

.filter-radio label {
  display: block;
  color: #000 !important;
  margin-bottom: 8px;
}

.filter-radio input {
  margin-right: 8px;
  cursor: pointer;
  pointer-events: auto;
}

.dropdown.organisations:hover .dropdown-menu {
  display: block;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  margin-right: 0.5rem;
}

input[type="radio"]:after {
  padding: 1px 1px;
  width: 1rem;
  height: 1rem;
  background-color: #949494;
  content: " ";
  font-size: 6px;
  opacity: 1;
  border-radius: 10px;
  font-weight: 600;
  position: absolute;
  top: 0px;
  left: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

input[type="radio"]:checked::after {
  content: "\f111";
  font-family: 'Font Awesome 6 Pro';
  background-color: #146B90;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: auto;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 8px;
  z-index: 1;
}

.dropdown-item {
  display: block;
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.css-1fdsijx-ValueContainer {
  padding: 5px 10px !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-1xc3v61-indicatorContainer {
  color: #000000;
}

#admin-dash-header .css-b62m3t-container>div {
  border-color: transparent !important;
  color: #000000;
  outline: none;
}

.css-b62m3t-container>div:hover {
  border-color: transparent !important;
  outline: none;
}

.course-model {
    min-width: auto !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000 !important;
  font-size: 26px !important;
}

.CircularProgressbar-path {
  stroke: #22A298 !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.licences-history-title{
  margin-top: -2rem;
 }

@media screen and (max-width: 992px) {
  body {
    font-size: 85%;
  }

  .btn-md {
    padding: 8px 30px !important;
  }

  .dashboard {
    min-height: auto;
  }

  .sidebar {
    width: 270px;
    z-index: 9;
    height: 100vh;
  }

  .sidebar-menu a {
    font-size: 1rem !important;
    margin-bottom: 30px;
  }

  .progressbar .CircularProgressbar {
    width: 35px;
    height: 35px;
   }

  .footer {
    padding: 40px 40px 20px;
  }

  .page__main {
    margin: 50px 0px 0px 0px;
  }

  .footer .font-20 {
    font-size: 0.938rem;
  }

  img.social-icons {
    width: 35px;
  }

  .footer-copyright {
    font-size: 0.938rem;
    padding-bottom: 1.5rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1024px) {

  .h3,
  h3 {
    font-size: 1.125rem !important;
  }

  .font-20 {
    font-size: 1.125rem;
  }

  .w-65 {
    width: 65%;
  }

  .header {
    padding: 18px 30px 18px 30px;
  }

  .sidebar {
    width: 240px;
    padding: 117px 15.35px 39px 30px;
  }

  .page__main {
    margin: 50px 0px 0px 240px;
  }

  .sticky-btn {
    width: 160px;
  }

  .sidebar-menu a {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 1rem;
  }

  .sidebar-menu.min-height {
    height: calc(100vh - 410px);
  }

  .table>:not(caption)>*>* {
    padding: 10px 0px !important;
    font-size: 14px;
  }

  .progressbar .CircularProgressbar {
    width: 35px;
    height: 35px;
  }

  .stats canvas{
    max-height: 402px;
  }

  /* .statistics.sold-used canvas {
    max-height: 1000px !important;
    display: inline-block !important;
  } */

  .font-26 {
    font-size: 1.125rem;
  }

  .btn-md {
    padding: 6px 35px;
  }

  .footer .font-20 {
    font-size: 0.938rem;
  }

  img.social-icons {
    width: 35px;
  }

  .footer-copyright {
    font-size: 0.725rem;
    line-height: 1.5;
  }
}


@media screen and (min-width: 1200px) {

  .sidebar-menu.min-height {
    height: calc(100vh - 420px);
  }

  .table>:not(caption)>*>* {
    padding: 10px 0px !important;
    font-size: 12px;
  }

  .progressbar .CircularProgressbar {
    width: 35px;
    height: 35px;
}
}

@media screen and (min-width: 1440px) {
  .font-20 {
    font-size: 1.125rem !important;
  }

  .font-26 {
    font-size: 1.25rem !important;
  }

  .w-65 {
    width: 50% !important;
  }

  .page__main {
    margin: 50px 0px 0px 300px !important;
  }

  .nosidebar-page__main .dashboard {
    min-height: auto;
  }

  .header {
    padding: 18px 30px 18px 40px !important;
  }

  .sidebar {
    width: 300px !important;
    padding: 117px 15.35px 39px 40px !important;
  }

  .sticky-btn {
    width: 225px !important;
  }

  .sidebar-menu a {
    font-size: 1.25rem !important;
    margin-bottom: 30px !important;
    margin-top: 1rem !important;
  }

  .sidebar-menu.min-height {
    height: calc(100vh - 440px) !important;
  }

  .table>:not(caption)>*>* {
    padding: 10px 0px !important;
    font-size: 15px;
  }

  .btn-md {
    padding: 10px 50px !important;
  }

  .social-link {
    margin-right: 18px;
  }

  .footer .font-20 {
    font-size: 1.125rem !important;
  }

  .footer-copyright {
    font-size: 0.9rem !important;
    line-height: 1.5;
  }
}



.css-1fdsijx-ValueContainer {
  padding: 0.5rem 0.75rem !important;
}

.css-13cymwt-control {
  border-color: hsl(0deg 4.01% 13.11%) !important;
}

.css-13cymwt-control:hover {
  border-color: hsl(0deg 4.01% 13.11%) !important;
}

.css-b62m3t-container>div:hover {
  border-color: hsl(0deg 4.01% 13.11%) !important;
  outline: none;
}